import { buildNextRouterState } from '../history/buildNextRouterState'
import { isParsedUrlQueryInput, isUrlObject } from '../url'
import { withMd5Id } from '../genMd5Id'
import { ShopRoutes } from '../variables'

export const buildSearchUrlWithSearchTerm = (searchTerm: string): string | undefined => {
  const nextRouterState = buildNextRouterState(ShopRoutes.Search, {
    searchParams: withMd5Id({
      id: '',
      searchString: searchTerm,
    }),
  })
  if (isUrlObject(nextRouterState) && isParsedUrlQueryInput(nextRouterState.query)) {
    return `${nextRouterState.pathname}?state=${nextRouterState.query.state}`
  }
}
