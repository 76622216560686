import { styled } from '@mui/material/styles'

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

export const BtnToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    cursor: 'pointer',
    padding: '0.5rem',
  },
}))
