import { useEffect, useState } from 'react'
import { requestOxomiMetaData } from '@obeta/utils/lib/requestOxomiMetaData'
import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { OxomiGlobal } from '@obeta/models/lib/models/Oxomi'
import { datadogRum } from '@datadog/browser-rum'
import { searchByOxomiInfoPlayObject } from '@obeta/utils/lib/search/searchByOxomiInfoPlayObject'
import { useTranslation } from 'react-i18next'

type OxomiScriptVersion = 'v1' | 'v2'

declare global {
  interface Window {
    oxomi: OxomiGlobal
    oxomi_ready: VoidFunction
  }
}

let isOxomiScriptStartLoading = false

export const OXOMI_SEARCHLET_CONTAINER_ID = 'oxomi-searchlet-container'

const injectOxomiScript = (version?: OxomiScriptVersion) => {
  if (!isOxomiScriptStartLoading) {
    isOxomiScriptStartLoading = true
    fetch(
      version === 'v2'
        ? 'https://oxomi.com/assets/frontend/v2/oxomi.js'
        : 'https://oxomi.com/assets/frontend/oxomi.js'
    )
      .then((response) => response.text())
      .then((scriptContent) => {
        const modifiedScriptContent = scriptContent.replace(/http:/g, 'https:')

        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.textContent = modifiedScriptContent

        document.getElementsByTagName('head')[0].appendChild(script)
      })
      .catch((error) => {
        isOxomiScriptStartLoading = false
        console.error('Failed to load or modify Oxomi script:', error)
        datadogRum.addError(`injectOxomiScript error: ${error}`)
      })
  }
}

type UseOxomiIntegrationOptions = {
  documentId: string
  version?: OxomiScriptVersion
  skipInit?: boolean
}
export const useOxomiIntegration = (options: UseOxomiIntegrationOptions) => {
  const { documentId, version, skipInit } = options
  const [oxomiData, setOxomiData] = useState<{ portal: string; accessToken: string } | null>(null)
  const client = useApolloClient()
  const { t } = useTranslation()

  useEffect(() => {
    const getOxomiAccessToken = async () => {
      try {
        const response = await requestOxomiMetaData(client as ApolloClient<NormalizedCacheObject>)

        if (!response.oxomiToken) return
        if (response.oxomiPortal && response.oxomiToken) {
          setOxomiData({ portal: response.oxomiPortal, accessToken: response.oxomiToken })
        }

        injectOxomiScript(version)

        window.oxomi_ready = function oxomi_ready() {
          if (skipInit) {
            return
          }
          window.oxomi.init({
            user: 'obeta',
            portal: response.oxomiPortal,
            accessToken: response.oxomiToken,
            documentId: documentId,
            infoplayMenuTitle: t('START.OXOMI_CATALOG_SEARCH_ACTION'),
            infoplayItemLookup: searchByOxomiInfoPlayObject,
            errorHandler: function (obj) {
              console.error('Failed to init oxomi:', obj.json.message)

              datadogRum.addError(obj.json.message, {
                type: 'oxomi init',
                portal: response.oxomiPortal,
                accessToken: response.oxomiToken,
              })
            },
          })
        }
      } catch (err: unknown) {
        if (err instanceof Error) handleError(err)
      }
    }
    getOxomiAccessToken()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return oxomiData
}
