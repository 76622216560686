import { gql, useApolloClient } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { useUserSelectedStore } from './useUserSelectedStore'
import { useUserDataV2 } from './useUserDataV2'
import { useWarehouseContext } from '../stores/useWarehouseContext'
import { priceStockSearchQuery } from '../queries/priceStockSearchQuery'
import { useAppDomain } from './useAppDomain'
import { ShopDomainToCompany } from '@obeta/utils/lib/domains'

const recommendedProductsQuery = gql`
  query getRecommendedProductsByCompany($companyId: String) {
    getRecommendedProductsByCompany(companyId: $companyId) {
      id
      sapId
      isSendable
      isCutProduct
      title
      eans
      oxomiId
      supplierId
      dehaId
      obetaId
      unit
      articleDescription
      minimumAmount
      isTopseller
      type
      supplierImageData {
        large
      }
      imageData {
        images {
          large
        }
      }
      images {
        url
        width
      }
      packagingUnit
      priceDimension
    }
  }
`

export const useRecommendedProductsByCompany = () => {
  const { isLoggedIn, companyId } = useUserDataV2()
  const { selectedStore } = useUserSelectedStore()
  const client = useApolloClient()
  const domain = useAppDomain()

  const [products, setProducts] = useState<ProductAggregate[]>([])
  const mountRef = useRef(true)

  const unauthorizedUsersCompanyId = ShopDomainToCompany[domain]
  const userCompanyId = isLoggedIn ? companyId : unauthorizedUsersCompanyId

  const selectedStoreId = selectedStore?.id
  const { warehouseId } = useWarehouseContext()

  useEffect(() => {
    return () => {
      mountRef.current = false
    }
  }, [])

  useEffect(() => {
    const getRecomProducts = async () => {
      if (!mountRef.current) return
      const response = await client.query({
        query: recommendedProductsQuery,
        variables: { companyId: userCompanyId },
      })

      const result = response.data.getRecommendedProductsByCompany
      if (!mountRef.current) return
      setProducts(result)

      const sapIds = result.map((prod) => prod.sapId)
      if (sapIds.length && isLoggedIn) {
        const warehouseIds: string[] = [warehouseId]
        if (selectedStoreId) warehouseIds.push(selectedStoreId)

        const priceStocksResult = await client.query({
          query: priceStockSearchQuery,
          variables: {
            sapIds,
            warehouseIds,
          },
        })
        const productData: ProductAggregate[] = priceStocksResult.data.getProducts
        if (!mountRef.current) return
        setProducts((current) => {
          productData.forEach((data) => {
            const el = current.find((p) => p?.sapId === data?.sapId)
            if (el) {
              el.stock = data.stock
              el.prices = data.prices
            }
          })
          return [...current]
        })
      }
    }
    userCompanyId && getRecomProducts()
  }, [userCompanyId, isLoggedIn, selectedStoreId, client, warehouseId])

  return products
}
