import React from 'react'
import { CategoryIcon } from '../categories/Categories'
import { constructCatId } from '@obeta/utils/lib/categories'
import styles from './Category.module.scss'
import { BtnToolTip } from './BtnTooltip'

export interface ICategoryProps {
  id: string
  name: string
  onCategoryClicked?: (id: string, name: string) => void
}

export const Category: React.FC<ICategoryProps> = (props) => {
  const { id, name, onCategoryClicked } = props

  return (
    <BtnToolTip
      describeChild
      title={name}
      onClick={() => onCategoryClicked && onCategoryClicked(id, name)}
    >
      <div className={styles['each-category']}>
        <CategoryIcon key={id} catId={constructCatId(id)} size="lg" />
      </div>
    </BtnToolTip>
  )
}
