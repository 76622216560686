import React from 'react'
import { ArticleCarouselCard } from '@obeta/components/lib/product-card'
import { ProductsSwiper } from '../articleDetailsPage/ProductsSwiper'
import { useShoppingCartsV2 } from '@obeta/data/lib/hooks/useShoppingCartsV2'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import styles from './SaleProductSection.module.scss'
import clsx from 'clsx'
import { useRecommendedProductsByCompany } from '@obeta/data/lib/hooks/useRecomendedProductsByCompany'

export const SaleProductSection: React.FC = () => {
  const { user, isLoggedIn } = useUserDataV2()
  const { selectedStore: store } = useUserSelectedStore()
  const { mobile, tablet, tabletAll } = useBreakpoints()
  const { carts, activeCart } = useShoppingCartsV2()
  const recommendedProducts = useRecommendedProductsByCompany()

  return (
    <ProductsSwiper
      withNavigateButtons={!mobile}
      cardWrapperClassName={clsx(styles.cardWrapper)}
      elementsPerRow={mobile ? 'auto' : tablet ? 2 : 4}
      productElements={recommendedProducts.map((product, i) => (
        <ArticleCarouselCard
          key={i}
          productId={product.sapId}
          product={product}
          selectedStore={store}
          user={user}
          tablet={tabletAll}
          mobile={mobile}
          isLoggedIn={isLoggedIn}
          carts={carts}
          selectedCart={activeCart}
        />
      ))}
    />
  )
}
